@font-face {
  font-family: "frutiger-light";
  src: url("/assets/fonts/frutiger/frutiger-light.eot?#iefix");
  src: url("/assets/fonts/frutiger/frutiger-light.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/frutiger/frutiger-light.woff") format("woff"),
    url("/assets/fonts/frutiger/frutiger-light.ttf") format("truetype")
      /*, url("/assets/fonts/frutiger/frutiger-light.svg") format("svg")*/;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "frutigerLTW01-55Roman";
  src: url("/assets/fonts/frutiger/frutiger-roman.eot?#iefix");
  src: url("/assets/fonts/frutiger/frutiger-roman.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/frutiger/frutiger-roman.woff") format("woff"),
    url("/assets/fonts/frutiger/frutiger-roman.ttf") format("truetype")
      /*, url("/assets/fonts/frutiger/frutiger-roman.svg") format("svg")*/;
}
@font-face {
  font-family: "frutiger-italic";
  src: url("/assets/fonts/frutiger/frutiger-light-italic.eot?#iefix");
  src: url("/assets/fonts/frutiger/frutiger-light-italic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/frutiger/frutiger-light-italic.woff") format("woff"),
    url("/assets/fonts/frutiger/frutiger-light-italic.ttf") format("truetype")
      /*, url("/assets/fonts/frutiger/frutiger-light-italic.svg") format("svg")*/;
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "frutiger-bold";
  src: url("/assets/fonts/frutiger/frutiger-bold.eot?#iefix");
  src: url("/assets/fonts/frutiger/frutiger-bold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/frutiger/frutiger-bold.woff") format("woff"),
    url("/assets/fonts/frutiger/frutiger-bold.ttf") format("truetype")
      /*, url("/assets/fonts/frutiger/frutiger-bold.svg") format("svg")*/;
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icomoon/icomoon.eot?u78fjc");
  src: url("/assets/fonts/icomoon/icomoon.eot?u78fjc#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/icomoon/icomoon.woff2?u78fjc") format("woff2"),
    url("/assets/fonts/icomoon/icomoon.ttf?u78fjc") format("truetype"),
    url("/assets/fonts/icomoon/icomoon.woff?u78fjc") format("woff"),
    url("/assets/fonts/icomoon/icomoon.svg?u78fjc#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Optum-Icon-Font";
  src: url("/assets/fonts/cux/Optum-Icon-Font.eot?lwb8n7");
  src: url("/assets/fonts/cux/Optum-Icon-Font.eot?lwb8n7#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/cux/Optum-Icon-Font.woff2?lwb8n7") format("woff2"),
    url("/assets/fonts/cux/Optum-Icon-Font.ttf?lwb8n7") format("truetype"),
    url("/assets/fonts/cux/Optum-Icon-Font.woff?lwb8n7") format("woff"),
    url("/assets/fonts/cux/Optum-Icon-Font.svg?lwb8n7#Optum-Icon-Font")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
.ff_fl {
  font-family: "frutiger-light", Arial, Helvetica, sans-serif;
}
.ff_fr {
  font-family: "frutigerLTW01-55Roman", Arial, Helvetica, sans-serif;
}
.ff_fi {
  font-family: "frutiger-italic", Arial, Helvetica, sans-serif;
}
.ff_fb {
  font-family: "frutiger-bold", Arial, Helvetica, sans-serif;
}
.ff_o,
footer {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}
.ff_ob {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
}
.ff_oi {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 400;
}
