@media (min-width: 1200px) {
  .lg-clearfix {
    clear: both;
  }
}
@media (max-width: 1400px) {
  .lg-marB10 {
    margin-bottom: 10px;
  }
  .lg-marB20 {
    margin-bottom: 20px;
  }
  .lg-marB40 {
    margin-bottom: 40px;
  }
  .lg-marR0 {
    margin-right: 0;
  }
  .lg-padL30 {
    padding-left: 20px;
  }
  .lg-padT20 {
    padding-top: 20px;
  }
  .lg-padB20 {
    padding-bottom: 20px;
  }
  .lg-padT5 {
    padding-top: 5px;
  }
  .lg-pad30 {
    padding: 30px;
  }
  .lg-padR30 {
    padding-right: 30px;
  }
  .lg-f15 {
    font-size: 15px;
  }
}
@media (min-width: 767px) and (max-width: 1199px) {
  .md-clearfix {
    clear: both;
  }
}
@media (max-width: 1200px) {
  .md-hide {
    display: none !important;
  }
  .md-show {
    display: block !important;
  }
  .md-marR0 {
    margin-right: 0;
  }
  .md-padR10 {
    padding-right: 10px;
  }
  .md-padL10 {
    padding-left: 10px;
  }
}
@media (max-width: 992px) {
  .sm-hide {
    display: none !important;
  }
  .sm-show {
    display: block !important;
  }
  .sm-fDefault {
    font-size: inherit;
  }
  .sm-padT30 {
    padding-top: 30px;
  }
  .sm-padB30 {
    padding-bottom: 30px;
  }
  .sm-brHide br {
    display: none;
  }
  .sm-marR0 {
    margin-right: 0;
  }
  .sm-marB10 {
    margin-bottom: 10px;
  }
  .sm-marB20 {
    margin-bottom: 20px;
  }
  .sm-marB30 {
    margin-bottom: 30px;
  }
  .sm-marB50 {
    margin-bottom: 50px;
  }
  .sm-pad30 {
    padding: 30px;
  }
  .sm-padL0 {
    padding-left: 0;
  }
  .sm-padL10 {
    padding-left: 10px;
  }
  .sm-padL20 {
    padding-left: 20px;
  }
  .sm-padT0 {
    padding-top: 0;
  }
  .sm-padT10 {
    padding-top: 10px;
  }
  .sm-padT20 {
    padding-top: 20px;
  }
  .sm-padB10 {
    padding-bottom: 10px;
  }
  .sm-padR10 {
    padding-right: 10px;
  }
  .sm-padR20 {
    padding-right: 20px;
  }
}
@media (max-width: 767px) {
  .xs-hide {
    display: none !important;
  }
  .xs-show {
    display: block !important;
  }
  .xs-static {
    position: static !important;
  }
  .xs-relative {
    position: relative !important;
  }
  .xs-noFloat {
    float: none !important;
  }
  .xs-marAuto {
    margin: auto !important;
  }
  .xs-fDefault {
    font-size: inherit !important;
  }
  .xs-pad10 {
    padding: 10px;
  }
  .xs-pad20 {
    padding: 20px;
  }
  .xs-padT0 {
    padding-top: 0;
  }
  .xs-padT10 {
    padding-top: 10px;
  }
  .xs-padB10 {
    padding-bottom: 10px;
  }
  .xs-padB0 {
    padding-bottom: 0;
  }
  .xs-padT20 {
    padding-top: 20px;
  }
  .xs-padB20 {
    padding-bottom: 20px;
  }
  .xs-f1emH {
    font-size: 1.5em;
  }
  .xs-f1emHH {
    font-size: 1.25em;
  }
  .xs-c {
    text-align: center;
  }
  .xs-padT50 {
    padding-top: 50px;
  }
  .xs-padB50 {
    padding-bottom: 50px;
  }
  .xs-marB0 {
    margin-bottom: 0;
  }
  .xs-marT0 {
    margin-top: 0;
  }
  .xs-marB10 {
    margin-bottom: 10px;
  }
  .xs-marB20 {
    margin-bottom: 20px;
  }
  .xs-marB30 {
    margin-bottom: 30px;
  }
  .xs-marB35 {
    margin-bottom: 35px;
  }
  .xs-brHide br {
    display: none;
  }
  .xs-row- {
    margin-left: -10px;
    margin-right: -10px;
  }
  .xs-pad0 {
    padding: 0 !important;
  }
  .xs-marR10 {
    margin-right: 10px;
  }
  .xs-padL10 {
    padding-left: 10px;
  }
  .xs-padL20 {
    padding-left: 20px;
  }
  .xs-padR20 {
    padding-right: 20px;
  }
  .xs-padR10 {
    padding-right: 10px;
  }
  .xs-padL0 {
    padding-left: 0;
  }
  .xs-padR0 {
    padding-right: 0;
  }
  .xs-maxHightAuto {
    max-height: none !important;
  }
  .xs-wAuto {
    width: auto !important;
  }
}
@media (max-width: 479px) {
  .xxs-hide {
    display: none !important;
  }
  .xxs-show {
    display: block !important;
  }
  .xxs-static {
    position: static !important;
  }
  .xxs-all100 * {
    width: 100%;
    display: block;
    margin-bottom: 5px;
  }
  .xxs-fDefault {
    font-size: inherit;
  }
  .xxs-marB0 {
    margin-bottom: 0;
  }
  .xxs-f1emH {
    font-size: 1.5em;
  }
  .xxs-brHide br {
    display: none;
  }
}
