.login-container {
  max-width: 700px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 205px;
  margin: 20px 0 30px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.login-content {
  margin: 30px;

  h4 {
    margin: 0 0 20px;
  }
}

.link-btn {
    margin-top: 0px;

  p {
    font-size: 13px;
  }

  .button-secondary {
    background: none !important;
    border: 0;
    border-radius: 0;
    color: #006ca2 !important;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    margin: 0 !important;
    padding: 0 !important;
    vertical-align: baseline;

    &:active {
      box-shadow: none;
      text-decoration: underline;
    }

    &:focus {
      outline: none;
    }
  }
}
.login-figure {
  width: 255px;

  img {
    height: 100%;
    // max-width: none;
  }
}
.Skeleton{
  margin-bottom:12px;
}
.hideSubmit{
  visibility: hidden;
  max-height: 2px;
}
.mb20{
  margin-bottom:20px;
}
.height-const{
  min-height: 95px;
}
.mt15{
margin-top: 15px;
}
.mt42{
  margin-top: 42px;
}
