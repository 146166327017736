.btn {
  border-radius: 3px;
  padding: 7px 20px;
  border: none;
  display: inline-block;
  font-size: 1.2rem;
  border: solid 1px #e87722;
  background-color: transparent;
  color: #e87722;
  text-align: center;
  cursor: pointer;
  transition: all 0.35s ease;
  vertical-align: middle;
  text-decoration: none;
}
.btn:focus {
  outline: 0;
}
.btn:hover {
  background-color: #e87722;
  border: solid 1px #e87722;
  color: #ffffff;
  text-decoration: none;
}
.btn + .btn {
  margin-left: 15px;
}
input[type="file"].btn {
  padding: 5px 5px 4px;
}
.btn.icon {
  padding: 4px 20px;
}
.btn.cap {
  text-transform: uppercase;
}
.btn.fill {
  background-color: #e87722;
  color: #ffffff;
}
.btn.fill:hover {
  color: #e87722;
  background-color: transparent;
}
.btn.btnRound {
  border-radius: 20px;
  padding: 5px 10px;
}
.btn.text {
  background: none;
  color: inherit;
  padding: 0;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}
.btn.text:hover {
  background-color: transparent;
}
.btn.play {
  width: 92px;
  height: 92px;
  border: solid 10px #fff;
  border-radius: 50px;
}
.btn.play:after {
  border: solid 23px #ffffff;
  border-top: solid 23px transparent;
  border-bottom: solid 23px transparent;
  border-right: 0;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -22px;
  content: "";
}
.btn.play:hover {
  box-shadow: 0 0 5px #fff;
}
.btn.black {
  border: solid 1px #52545a;
  color: #52545a;
}
.btn.black:hover {
  color: #ffffff;
  background-color: #52545a;
}
.btn.black.fill {
  color: #ffffff;
  background-color: #52545a;
}
.btn.black.fill:hover {
  color: #52545a;
  background-color: transparent;
}
.alertOverlay {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: table;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 10px;
}
.alertOverlay .text {
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 5px #000000;
  color: #000000;
  display: block;
  font-size: 1.5em;
  left: 50%;
  margin: -20px auto auto -125px;
  padding: 20px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 250px;
  z-index: 100;
}
.alertOverlay .text > span {
  position: absolute;
  right: 5px;
  top: 0;
  cursor: pointer;
}
.btnClose {
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 10px;
  right: 10px;
  border: solid 1px #ffffff;
  z-index: 1;
}
.btnClose:hover:before,
.btnClose:hover:after {
  background: #000000;
}
.btnClose:before,
.btnClose:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #ffffff;
}
.btnClose::before {
  transform: rotate(45deg);
}
.btnClose::after {
  transform: rotate(-45deg);
}
/* dl's ////////////////// */
dl dt {
  /* background-color: ;
  color: ; */
  margin-bottom: 1.25rem;
  position: relative;
  cursor: pointer;
}
dl dt > .container {
  padding: 0.75em 4.5em 0.75em 0.75em;
  position: static;
}
dl dt span {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  /* background-color: ; */
  width: 4em;
  border-left: solid 0.15em #ffffff;
}
dl dt span:before,
dl dt span:after {
  width: 1.5em;
  height: 4px;
  background-color: #ffffff;
  display: block;
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -0.75em;
  top: 50%;
  margin-top: -2px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
}
dl dt span:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
dl dt.active span:before,
dl dt.active span:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
dl dt.active span:after {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
dl dd {
  margin-top: -1em;
  padding: 1em 0;
}
dl dd dt {
  background-color: rgba(0, 0, 0, 0.2);
  color: #000000;
  margin-bottom: 0.25em;
}
dl dd dt span {
  background-color: #000000;
}
dl dd dd {
  margin-top: 0;
}
dl dd.child {
  padding: 0;
}
/* List items */
ul li.selected {
  color: #000000;
}
ul li.selected a {
  color: #000000;
}

ul.inline {
  display: block;
  list-style: none;
}
ul.inline.sep li {
  border-right: 1px solid #ffffff;
}
ul.inline.bar li {
  border-right: solid 1px #000000;
}
ul.inline li {
  display: inline-block;
  padding-right: 0.5em;
  padding-left: 0.25em;
}
ul.inline li:last-child {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
}
ul.inlineBlock li {
  display: inline-block;
  vertical-align: middle;
}
ul.table {
  list-style: none;
  display: table;
  border-collapse: collapse;
}
ul.table.bordered li {
  padding: 10px;
  border: solid 1px #000000;
}
ul.table li {
  display: table-cell;
  vertical-align: middle;
}
ul.table li.sep {
  padding: 50px;
}
ul.table li.sep span {
  width: 13px;
  height: 21px;
  // background: url('/assets/images/icon/icons.png') left -1708px no-repeat;
  display: block;
}
ul.default li {
  list-style: disc;
  list-style-position: outside;
}
ul.default.padL0 {
  padding-left: 0;
}
ul.padL1em {
  padding-left: 1em;
}
ul.altBgList li {
  padding: 0.5em 0;
}
ul.altBgList li:nth-child(2n) {
  background-color: #efefee;
}
ul.file li {
  padding-left: 20px;
  position: relative;
}
ul.file li a[href$=".pdf"]:before {
  // background: url('/assets/images/icon/iconPdf.png') center center no-repeat;
  content: "";
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  top: 1px;
  left: 0;
}
ul.liDoc {
  list-style: none;
  padding-left: 0;
  -webkit-margin-start: 0;
}
ul.liDoc li {
  margin-bottom: 5px;
  position: relative;
  padding-left: 30px;
  min-height: 32px;
}
ul.liDoc li img,
ul.liDoc li span.oi-document {
  left: 0;
  position: absolute;
  top: 0;
}
ul.liDoc li span.oi-document {
  font-size: 32px;
  width: 32px;
  line-height: 0.75;
  text-indent: -4px;
}
ul.liDoc li a {
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
  width: auto;
}
ul.liDoc li:last-child {
  margin-bottom: 0;
}
/* Tabs */
.tabWrapper ul {
  padding: 0;
  list-style: none;
}
.tabWrapper .tabs-menu {
  padding: 1em 0 0;
  color: #ffffff;
  border-bottom: solid 1px #282a2e;
  font-family: "frutigerLTW01-55Roman";
  font-size: 14px;
}
.tabWrapper .tabs-menu ul {
  -webkit-margin-start: 0;
}
.tabWrapper .tabs-menu li {
  float: left;
  margin-right: 5px;
  border: solid 1px transparent;
  position: relative;
  bottom: -2px;
}
/* .tabWrapper .tabs-menu li.current,
.tabWrapper .tabs-menu li:hover {
  background-color: #ffffff;
  z-index: 5;
  color: #282a2e;
  border: solid 1px #282a2e;
  border-bottom: solid 1px #ffffff;
}
.tabWrapper .tabs-menu li.current a,
.tabWrapper .tabs-menu li:hover a {
  color: #282a2e;
} */

.tabWrapper .tabs-menu li a {
  background-color: #282a2e;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 1px solid #282a2e;
  border-bottom: 0px;
}

.tabWrapper .tabs-menu li a.active,
.tabWrapper .tabs-menu li a:hover {
  color: #282a2e;
  background-color: #fff;
}

.tabWrapper .tabs-menu li a {
  padding: 10px 30px 9px;
  color: inherit;
  text-decoration: none;
  display: block;
  min-width: 230px;
  text-align: center;
}
.tabWrapper .tab .tab-content {
  padding: 1em 0;
  display: none;
}
.tabWrapper .tab .tab-content:first-child {
  display: block;
}
.tabWrapper .tab label {
  font-size: 14px;
  font-family: "frutiger-light", Arial, Helvetica, sans-serif;
  margin-left: 8px;
}
.tabWrapper .tab .cusCheck label {
  font-size: inherit;
}
/* Breadcrumbs */
.breadcrumb {
  padding-left: 0px;
  font-size: 15px;
}
.breadcrumb li {
  display: inline-block;
  position: relative;
  padding-right: 5px;
}
.breadcrumb li a {
  padding-right: 5px;
}
.breadcrumb li a:link,
.breadcrumb li a:active,
.breadcrumb li a:visited {
  color: #006ca2;
}
.breadcrumb li:after {
  content: ">";
  display: inline-block;
}
.breadcrumb li:last-child:after {
  display: none;
}
/* Seperators */
.sep {
  position: relative;
  overflow: auto;
  padding-bottom: 1px;
}
.sep:after {
  content: "";
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #ccc;
  position: absolute;
  display: block;
}
.sep.sep25:after {
  left: 275px;
}
.sep.sep25 .col-sm-3 {
  min-width: 275px;
  padding-right: 30px;
}
.sep.sep25 .col-sm-9 {
  max-width: 635px;
  padding-left: 30px;
}
/* pagination */
aside.pagination {
  text-align: center;
  position: relative;
  padding: 5px;
}
aside.pagination .prev,
aside.pagination .next {
  display: inline-block;
  width: auto;
  color: #006ca2;
  padding: 0 10px;
}
aside.pagination .prev:hover,
aside.pagination .next:hover {
  text-decoration: none;
  color: #05386b;
  cursor: pointer;
}
aside.pagination .prev:hover span,
aside.pagination .next:hover span {
  text-decoration: underline;
}
aside.filterData {
  background-color: #dddddd;
  border: solid 2px #ccc;
  font-size: 13px;
}
aside.filterData ul {
  list-style: none;
  padding-left: 0;
  font-weight: bold;
}
aside.filterData ul li {
  float: left;
  padding: 3px 10px 3px;
}
aside.filterData ul li.liName {
  width: 70%;
}
aside.filterData ul li.liDate {
  width: 30%;
  padding-left: 10px;
  border-left: solid 2px #ccc;
}
aside.filterData ul li span {
  cursor: pointer;
}
aside.filterData ul li span.img {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
  margin-left: 5px;
}
.sortedTree li {
  position: relative;
}
.sortedTree li span.more {
  position: absolute;
  right: 0;
  width: 27.5%;
  padding-left: 5px;
}
.sortedTree li li span.more {
  right: 17px;
}
.sortedTree li li li span.more {
  right: 34px;
}
.sortedTree li li li li span.more {
  right: 51px;
}
.sortedTree li li li li li span.more {
  right: 68px;
}
.sortedTree li li li li li li span.more {
  right: 85px;
}
.mCS_no_scrollbar .sortedTree li span.more {
  width: 30%;
}
body a.dataPagingRelativeLink {
  text-decoration: none;
}
body a.dataPagingRelativeLink:hover span {
  text-decoration: underline;
}
