.bas-container {
  // max-width: 870px;
  border: 1px solid #000;
  border-radius: 20px;
  height: 315px;
  margin: 30px 25px 30px;
  overflow: auto;
  display: flex;
  justify-content: space-between;
}

.boxx {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 300px;
  min-height: 350px;
  background: #fff;
  color: #9e9e9e;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
  line-height: 35px;
  font-family: "Forte";
  font-size: 20px;
}

.mb5 {
  margin-bottom: 5px;
}

.ml40 {
  margin-left: 40px;
}
.mr75 {
  margin-right: 55px !important;
}
.mt25 {
  margin-top: 25px;
}

.width150 {
  width: 150px !important;
}
.padTB0 {
  padding: 0px 8px !important;
  min-width: 35px;
}
//---------------

.QuestionHeader {
  margin: 15px 0px 0px 0px;
  color: #434448;
}

.InputFieldLabels {
  display: flex;
}

.mb-0 {
  margin-bottom: 0px !important;
}
.mr145 {
  margin-right: 145px !important;
}
.mr126 {
  margin-right: 126px !important;
}
.mb30pl0 {
  margin-bottom: 30px !important;
  padding-left: 0px !important;
}
.criteriaFieldLabelsSpacing {
  margin-right: 128px;
  margin-bottom: 15px;
}
.searchFieldLabelsSpacing {
  margin-right: 144px;
  margin-bottom: 15px;
}
.question {
  //margin: 0 0 15px;
  color: #434448;
}
.questionFont {
  font-family: "Frutiger-Bold", Arial, sans-serif !important;
  color: #434448;
}

.validationmsg {
  font-family: "Open Sans" !important;
  color: #d81818;
  font-size: 13px !important;
  font-weight: 700;
}

.removeIcon {
  color: #e87722;
  font-size: 2rem !important;
  margin-bottom: 5px;
}

.fieldValidationmsg {
  font-family: "Open Sans" !important;
  color: #d81818;
  font-size: 14px !important;
  font-weight: 700;
}

.errorIcon {
  position: absolute;
  padding: 0 0 0 25px !important;
  bottom: 100%;
  left: 0;
  white-space: nowrap;
  margin-bottom: 5px;

  svg {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.pad0 {
  padding: 0 !important;
}

.errorBottomMsg {
  font-family: "Open Sans" !important;
  color: #d81818;
  font-size: 13px !important;
  font-weight: 400;
}

.hintMsg {
  font-family: "Open Sans" !important;
  color: #5E5E5E;
  font-size: 13px !important;
  font-weight: 400;
}

input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
  color: black !important;
}

.body-text-small,
small,
.small,
.milli {
  font-size: 14px;
}

.BusinessXSMInput {
  width: 250px !important;
  height: 30px !important;
  margin: 0px 10px 10px 0px;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #000 !important;
  &:active {
    border: 2px solid #63666a;
  }
}
.select-dropdown {
  width: 205px;
  height: 30px;
  margin: 0px 20px 10px 0px;
  padding-left: 2px;
  border-radius: 3px;
  border: 1px solid #000;
  outline: none;
  font-size: 13px;
  -webkit-appearance: menulist;
  /* for webkit browsers */
  -moz-appearance: menulist;
  /* for firefox */
  appearance: auto;
  /* for modern browsers */
}
.greyBorder {
  border: 1px solid #63666a;
  color: #63666a;
}

.inputsize {
  width: 185px;
  // margin-bottom: 50px;
}

.button.button-primary-submit,
input[type="submit"].button-primary-submit {
  border-color: #e87722;
  background-color: #e87722;
  color: #ffffff;
  width: 109px;
}

.textTransform {
  text-transform: initial !important;
}

.required-file,
.required-files {
  &.noGutter .MuiToolbar-gutters {
    padding-left: 0;
    min-height: 20px;
  }
}

.required-file .MuiTablePagination-caption:after {
  content: " file";
}
.required-files .MuiTablePagination-caption:after {
  content: " files";
}

.color {
  color: #e87722;
}
.minWidth45 {
  min-width: 45px !important;
}
.height {
  height: 30px;
}
