ul.social {
  border-top: solid 1px #d1d9db;
}
ul.social label {
  display: inline-block;
  margin-right: 10px;
}
ul.social li {
  display: inline-block;
  margin-right: 10px;
}
ul.social li img {
  display: inline-block;
}
