.error {
  color: #c60f13;
  padding: 5px 0;
}
#loading,
.loading {
  position: absolute;
  right: 15px;
  top: 13px;
}
/* Common Buttons */
button {
  cursor: pointer;
}
/* Form Controls */
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
select,
textarea,
.form fieldset div.select {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 6px 10px;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: 12px;
  /* color: ; */
  width: 100%;
  height: 30px;
}
select[multiple="multiple"] {
  padding: 0;
  height: 180px;
}
select[multiple="multiple"] option {
  padding: 8px 10px;
}
textarea {
  min-height: 8em;
  max-height: 8em;
  resize: none;
}
// input[type="text"].hasDatepicker {
//   width: 80%;
//   margin-right: 5px;
// }
input[type="text"].hasDatepicker + img.ui-datepicker-trigger {
  vertical-align: middle;
}
.radioList > label,
.checkList > label {
  display: inline-block;
  margin-right: 2em;
}
.radioList > label input[type="radio"],
.checkList > label input[type="radio"],
.radioList > label input[type="checkbox"],
.checkList > label input[type="checkbox"] {
  float: left;
  margin: 1px 3px 0 0;
}
.oi-calendar,
button.ui-datepicker-trigger {
  float: right;
  margin-top: 5px;
  font-size: 22px;
  color: #e87722;
  margin-right: 2px;
  line-height: 1.1;
}
button.ui-datepicker-trigger {
  background: none;
  border: none;
  padding: 0;
}
button.ui-datepicker-trigger img {
  display: none;
}
.form fieldset.merged {
  position: relative;
}
.form fieldset.merged .cntl {
  min-width: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.form fieldset.merged .cntl.search {
  // background: url('/assets/images/icon/icon-searchSml.png') center center no-repeat;
  width: 45px;
  opacity: 0.3;
  padding: 0;
}
.form fieldset.merged .cntl:hover {
  opacity: 1;
}
.form fieldset label {
  display: inline-block;
  margin-bottom: 7px;
  color: #505152;
  font-family: "Frutiger-Light", Arial, sans-serif;
  font-size: 14px;
}
.form fieldset .radioList > label,
.form fieldset .checkList > label {
  color: inherit;
  display: inline-block;
  font-family: inherit;
  font-size: 12px;
  margin-bottom: 0;
}
.form fieldset .radioList > label input[type="radio"],
.form fieldset .checkList > label input[type="radio"],
.form fieldset .radioList > label input[type="checkbox"],
.form fieldset .checkList > label input[type="checkbox"] {
  margin-top: 2px;
}
.form.labInline label {
  padding-top: 0.5em;
}
.form.text input[type="text"],
.form.text input[type="password"],
.form.text input[type="email"],
.form.text input[type="tel"],
.form.text select,
.form.text textarea {
  border: none;
  padding: 0;
}
.form.fieldsetMarB fieldset {
  margin-bottom: 25px;
}
.form.fieldsetMarB2 fieldset {
  margin-bottom: 2em;
}
/* Form Select Controls */
.cusSelect div.select {
  height: 30px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.cusSelect .select .styledSelect {
  background: none;
  left: 0em;
  padding: 0.55em 20px 0.5em 10px;
  position: absolute;
  right: 0em;
  top: 0em;
  bottom: 0em;
  font-size: 12px;
  font-family: inherit;
}
.cusSelect .select .styledSelect:after {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #ccc transparent transparent;
  position: absolute;
  top: 50%;
  right: 6px;
  margin-top: -2px;
}
.cusSelect .select .styledSelect:before {
  border-left: 2px solid #fff;
  content: "";
  height: 100%;
  position: absolute;
  right: 40px;
  top: 0;
  width: 0;
  z-index: 100;
  display: none;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .cusSelect .select .styledSelect .styledSelect:before {
    height: 100%;
  }
}
.cusSelect .select .options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 4;
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  color: #333;
  list-style: none;
  max-height: 20rem;
  overflow: auto;
  font-size: 12px;
}
.cusSelect .select .options input {
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  border-bottom: solid 1px #ccc;
  opacity: 0.5;
  // background: url('/assets/images/icon/icon-search.png') 98% center no-repeat;
  background-size: auto 50%;
}
.cusSelect .select .options li {
  margin: 0;
  padding: 5px 10px;
  list-style: none;
}
.cusSelect .select .options li:hover {
  background: #eaeaea;
}
.cusSelect .select .options li.selected {
  background: #f3f3f3;
}
/* custom checkbox */
.cusCheck label {
  cursor: pointer;
  position: relative;
  padding-left: 20px;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cusCheck input[type="checkbox"] {
  display: none;
}
.cusCheck label:before {
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid #4e4e4e;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 4px;
}
.cusCheck input[type="checkbox"]:checked ~ label:before {
  background-color: #4e4e4e;
}
input.error {
  border: 1px solid #c60f13;
  background-color: #fff1ff;
}
.cusSelect div.select.Selecterror {
  border: 1px solid #c60f13;
  background-color: #fff1ff;
}
