.button-none {
  border: 0;
  cursor: pointer;
}
a.button {
  text-decoration: none !important;
}
.button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  font-size: 12px;
  font-family: "Frutiger-Light";
  letter-spacing: 3px;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  line-height: normal;
  text-decoration: none;
  vertical-align: middle;
  padding: 6px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-clip: border-box;
  -webkit-transition: all .25s;
  transition: all 0.25s;
  border-color: #505152;
  background-color: #505152;
  color: #ffffff;
}
.button:active,
input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active {
  outline: none;
  box-shadow: 0 0 6px 1px #e87722;
}
.button.disabled,
input[type="submit"].disabled,
input[type="reset"].disabled,
input[type="button"].disabled,
.button.button-disabled,
input[type="submit"].button-disabled,
input[type="reset"].button-disabled,
input[type="button"].button-disabled,
.button[disabled],
input[type="submit"][disabled],
input[type="reset"][disabled],
input[type="button"][disabled] {
  pointer-events: none;
  cursor: not-allowed;
  opacity: .5;
}
.button.button-full-width,
input[type="submit"].button-full-width,
input[type="reset"].button-full-width,
input[type="button"].button-full-width {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}
.button.button-small,
input[type="submit"].button-small,
input[type="reset"].button-small,
input[type="button"].button-small {
  font-size: 12px;
  text-transform: none;
  padding: 4px 8px;
}
.button.button-large,
input[type="submit"].button-large,
input[type="reset"].button-large,
input[type="button"].button-large {
  font-size: 18px;
  padding: 8px 16px;
}
.button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.button:active,
input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active {
  color: #505152;
  background-color: transparent;
}
.button.button-reverse,
input[type="submit"].button-reverse,
input[type="reset"].button-reverse,
input[type="button"].button-reverse {
  color: #505152;
  background-color: transparent;
}
.button.button-reverse:hover,
input[type="submit"].button-reverse:hover,
input[type="reset"].button-reverse:hover,
input[type="button"].button-reverse:hover,
.button.button-reverse:active,
input[type="submit"].button-reverse:active,
input[type="reset"].button-reverse:active,
input[type="button"].button-reverse:active {
  background-color: #505152;
  color: #ffffff;
}
.button.block,
input[type="submit"].block,
input[type="reset"].block,
input[type="button"].block {
  text-align: center;
}
.button.button-tag,
input[type="submit"].button-tag,
input[type="reset"].button-tag,
input[type="button"].button-tag {
  border-color: #505152;
  background-color: #505152;
  color: #ffffff;
  border-style: dashed;
}
.button.button-tag:hover,
input[type="submit"].button-tag:hover,
input[type="reset"].button-tag:hover,
input[type="button"].button-tag:hover,
.button.button-tag:active,
input[type="submit"].button-tag:active,
input[type="reset"].button-tag:active,
input[type="button"].button-tag:active {
  color: #505152;
  background-color: transparent;
}
.button.button-tag.button-reverse,
input[type="submit"].button-tag.button-reverse,
input[type="reset"].button-tag.button-reverse,
input[type="button"].button-tag.button-reverse {
  color: #505152;
  background-color: transparent;
}
.button.button-tag.button-reverse:hover,
input[type="submit"].button-tag.button-reverse:hover,
input[type="reset"].button-tag.button-reverse:hover,
input[type="button"].button-tag.button-reverse:hover,
.button.button-tag.button-reverse:active,
input[type="submit"].button-tag.button-reverse:active,
input[type="reset"].button-tag.button-reverse:active,
input[type="button"].button-tag.button-reverse:active {
  background-color: #505152;
  color: #ffffff;
}
.button.button-tag.block,
input[type="submit"].button-tag.block,
input[type="reset"].button-tag.block,
input[type="button"].button-tag.block {
  text-align: center;
}
.button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary {
  border-color: #e87722;
  background-color: #e87722;
  color: #ffffff;
}
.button.button-primary:hover,
input[type="submit"].button-primary:hover,
input[type="reset"].button-primary:hover,
input[type="button"].button-primary:hover,
.button.button-primary:active,
input[type="submit"].button-primary:active,
input[type="reset"].button-primary:active,
input[type="button"].button-primary:active {
  color: #e87722;
  background-color: transparent;
}
.button.button-primary.button-reverse,
input[type="submit"].button-primary.button-reverse,
input[type="reset"].button-primary.button-reverse,
input[type="button"].button-primary.button-reverse {
  color: #e87722;
  background-color: transparent;
}
.button.button-primary.button-reverse:hover,
input[type="submit"].button-primary.button-reverse:hover,
input[type="reset"].button-primary.button-reverse:hover,
input[type="button"].button-primary.button-reverse:hover,
.button.button-primary.button-reverse:active,
input[type="submit"].button-primary.button-reverse:active,
input[type="reset"].button-primary.button-reverse:active,
input[type="button"].button-primary.button-reverse:active {
  background-color: #e87722;
  color: #ffffff;
}
.button.button-primary.block,
input[type="submit"].button-primary.block,
input[type="reset"].button-primary.block,
input[type="button"].button-primary.block {
  text-align: center;
}
.button.button-secondary,
input[type="submit"].button-secondary,
input[type="reset"].button-secondary,
input[type="button"].button-secondary {
  border-color: #ce4b27;
  background-color: #ce4b27;
  color: #ffffff;
}
.button.button-secondary:hover,
input[type="submit"].button-secondary:hover,
input[type="reset"].button-secondary:hover,
input[type="button"].button-secondary:hover,
.button.button-secondary:active,
input[type="submit"].button-secondary:active,
input[type="reset"].button-secondary:active,
input[type="button"].button-secondary:active {
  color: #ce4b27;
  background-color: transparent;
}
.button.button-secondary.button-reverse,
input[type="submit"].button-secondary.button-reverse,
input[type="reset"].button-secondary.button-reverse,
input[type="button"].button-secondary.button-reverse {
  color: #ce4b27;
  background-color: transparent;
}
.button.button-secondary.button-reverse:hover,
input[type="submit"].button-secondary.button-reverse:hover,
input[type="reset"].button-secondary.button-reverse:hover,
input[type="button"].button-secondary.button-reverse:hover,
.button.button-secondary.button-reverse:active,
input[type="submit"].button-secondary.button-reverse:active,
input[type="reset"].button-secondary.button-reverse:active,
input[type="button"].button-secondary.button-reverse:active {
  background-color: #ce4b27;
  color: #ffffff;
}
.button.button-secondary.block,
input[type="submit"].button-secondary.block,
input[type="reset"].button-secondary.block,
input[type="button"].button-secondary.block {
  text-align: center;
}
.button.button-animate,
input[type="submit"].button-animate,
input[type="reset"].button-animate,
input[type="button"].button-animate {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}
.button.button-animate:before,
input[type="submit"].button-animate:before,
input[type="reset"].button-animate:before,
input[type="button"].button-animate:before {
  width: 50px;
  height: 50px;
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  margin-top: -25px;
  margin-left: -25px;
  background-color: #efefee;
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.4s;
  transition-duration: .4s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.button.button-animate:hover,
input[type="submit"].button-animate:hover,
input[type="reset"].button-animate:hover,
input[type="button"].button-animate:hover,
.button.button-animate:focus,
input[type="submit"].button-animate:focus,
input[type="reset"].button-animate:focus,
input[type="button"].button-animate:focus,
.button.button-animate:active,
input[type="submit"].button-animate:active,
input[type="reset"].button-animate:active,
input[type="button"].button-animate:active {
  color: #282a2e !important;
  border-color: #b7b7b7;
}
.button.button-animate:hover:before,
input[type="submit"].button-animate:hover:before,
input[type="reset"].button-animate:hover:before,
input[type="button"].button-animate:hover:before,
.button.button-animate:focus:before,
input[type="submit"].button-animate:focus:before,
input[type="reset"].button-animate:focus:before,
input[type="button"].button-animate:focus:before,
.button.button-animate:active:before,
input[type="submit"].button-animate:active:before,
input[type="reset"].button-animate:active:before,
input[type="button"].button-animate:active:before {
  -webkit-transform: scale(10);
  transform: scale(10);
}
.button-full-width.button.button-animate:hover:before,
.button-full-widthinput[type="submit"].button-animate:hover:before,
.button-full-widthinput[type="reset"].button-animate:hover:before,
.button-full-widthinput[type="button"].button-animate:hover:before,
.button-full-width.button.button-animate:focus:before,
.button-full-widthinput[type="submit"].button-animate:focus:before,
.button-full-widthinput[type="reset"].button-animate:focus:before,
.button-full-widthinput[type="button"].button-animate:focus:before,
.button-full-width.button.button-animate:active:before,
.button-full-widthinput[type="submit"].button-animate:active:before,
.button-full-widthinput[type="reset"].button-animate:active:before,
.button-full-widthinput[type="button"].button-animate:active:before {
  transform: scale(30);
}
.button.noCap,
input[type="submit"].noCap,
input[type="reset"].noCap,
input[type="button"].noCap {
  text-transform: none;
}
.button + .button {
  margin-left: 10px;
}
.button + .button.block {
  margin-left: 0;
  margin-top: 10px;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
