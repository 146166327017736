span.invoiceTblScroll {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 590px;

  & > div {
    box-shadow: none;
  }
}

#CICInvoiceConsumerinvoiceAccessPageInvoiceDocAccessItem_table {
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}

.singleLine {
  white-space: nowrap;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.4;
}

.errDialog {
  .MuiDialog-paper {
    border-radius: 8px;
  }

  .rxCometForm
    .form-group.field
    .body-text-small:not(.productsCardText).header-notes {
    margin-bottom: 30px !important;
  }

  .MuiDialogTitle-root {
    padding: 36px 60px 12px 40px;
  }

  .MuiDialogContent-root {
    padding: 0px 60px 15px 40px;
    overflow-y: auto !important;

    .button {
      text-transform: inherit;

      & + .button {
        margin-left: 20px;
      }
    }
  }

  .MuiDialogContent-dividers {
    border: 0;
    padding: 0 0 0 40px;
    margin: 0px 60px 36px 0;
    min-height: 200px;
  }

  .MuiTypography-h2 {
    font-family: "Frutiger-Light", Arial, sans-serif;
    font-size: 36px;
    line-height: normal;
    letter-spacing: normal;
  }

  &.sm600 {
    // .MuiDialogContent-root {
    //   width: 600px;
    // }
    .MuiDialogContent-root .form-group .control-label {
      margin: 0 0 5px 0 !important;
      color: #505152;
      display: block;
    }
  }

  &.dialog-body {
    .MuiDialog-paperWidthSm {
      max-width: 400px;
    }
  }
}

.responsedialog {
  .MuiDialog-paper {
    border-radius: 8px;
  }

  .rxCometForm
    .form-group.field
    .body-text-small:not(.productsCardText).header-notes {
    margin-bottom: 30px !important;
  }

  .MuiDialogTitle-root {
    padding: 36px 60px 12px 40px;
  }

  .MuiDialogContent-root {
    padding: 18px 60px 0 40px;
    overflow-y: auto !important;

    .button {
      text-transform: inherit;

      & + .button {
        margin-left: 20px;
      }
    }
  }

  .MuiDialogContent-dividers {
    border: 0;
    padding: 0 0 0 40px;
    margin: 0px 60px 36px 0;
    min-height: 200px;
  }

  .MuiTypography-h2 {
    font-family: "Frutiger-Light", Arial, sans-serif;
    font-size: 36px;
    line-height: normal;
    letter-spacing: normal;
  }

  &.sm600 {
    // .MuiDialogContent-root {
    //   width: 600px;
    // }
    .MuiDialogContent-root .form-group .control-label {
      margin: 0 0 5px 0 !important;
      color: #505152;
      display: block;
    }
  }

  &.dialog-body {
    .MuiDialog-paperWidthSm {
      max-width: 400px;
    }
  }
}
