/* main */
.mainWrap {
  min-height: 100vh;
  padding-bottom: 30px;
  position: relative;
  min-width: 940px;
  max-width: 101rem;
  margin: auto;
  background-color: #ffffff;
}
.mainWrap main {
  padding: 0 15px;
  margin: 0;
}
main {
  padding: 0 15px;
}
.teamList {
  list-style: none;
  padding-left: 0;
}
.teamList li {
  margin-bottom: 20px;
}
.teamList li:nth-child(2n + 3) {
  clear: left;
}
.teamList li figure {
  border: solid 1px #c4cfd4;
  border-radius: 3px;
  border-bottom: solid 10px #282a2e;
  overflow: hidden;
}
.teamList li figure .imgWrapper {
  display: block;
  background-color: #eee;
  height: 157px;
}
.teamList li figure .imgWrapper img {
  display: block;
  max-height: 100%;
  margin: auto;
}
.teamList li figure figcaption {
  padding: 15px 20px;
}
.teamList li figure figcaption .name {
  margin: 0;
}
.teamList li figure figcaption .info {
  margin-bottom: 25px;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  background-color: #ffffff;
}
.teamList li figure figcaption a {
  text-transform: uppercase;
}
.proList {
  margin-top: 10px;
  list-style: none;
}
.proList li {
  margin-bottom: 20px;
  border: solid 1px #c4cfd4;
  border-radius: 3px;
  padding: 15px 0;
  position: relative;
  min-height: 100px;
}
.proList li:before {
  width: 1px;
  background-color: #ccc;
  content: "";
  position: absolute;
  left: 25%;
  top: 15px;
  bottom: 15px;
  display: block;
}
.proList li figure {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  width: inherit;
  left: 0;
}
.proList li figure img {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  max-width: calc(100% - 10px);
}
.proList li .aHnoU a:hover,
.proList li .aHnoU a:focus {
  text-decoration: none;
}
.proList li .btn {
  display: block;
  margin-left: 0;
  margin-bottom: 10px;
}
.proList li .btn:last-child {
  margin-bottom: 0;
}
.proList li p {
  margin-bottom: 0;
}
.leftBlock {
  min-width: 620px;
}
.leftBlock article ul {
  margin-left: 1em;
}
.rightBlock {
  padding-top: 20px;
  max-width: 290px;
}
.rightBlock article {
  border: solid 1px #c4cfd4;
  margin-bottom: 20px;
  border-radius: 3px;
}
.rightBlock article h6 {
  padding: 15px;
  border-bottom: solid 1px #c4cfd4;
  margin: 0;
  text-transform: inherit;
}
.rightBlock article h5 {
  margin-top: 0;
}
.rightBlock article .content,
.rightBlock article .caption {
  padding: 15px;
}
.rightBlock article .content > p,
.rightBlock article .caption > p {
  margin-bottom: 0;
}
.rightBlock article figure {
  border-bottom: solid 1px #c4cfd4;
}
.rightBlock article figure img {
  display: block;
  width: 100%;
}
.rightBlock article .list + .list {
  margin-top: 15px;
}
.rightBlock article li:empty {
  display: none;
}
.rightBlock ul.liDoc li {
  margin-bottom: 10px;
  padding-left: 30px;
}
.rightBlock ul.liDoc li a {
  line-height: 1.2;
}
.mCustomScrollbar.mCustomVertical {
  height: auto;
  overflow: auto;
  min-height: 550px;
}

.announcementContainer {
  margin-bottom: 30px;
}

.moreBtn {
  width: 100%;

  a {
    font-weight: 600;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;

    svg {
      vertical-align: top;
    }
  }
}
