table.table,
.table table {
  display: table;
  width: 100%;
  height: inherit;
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 12px;
}
table.table th,
.table table th {
  background: #dddddd;
  border-color: #ffffff #aca899 #919191 #ffffff;
  border-style: solid;
  border-width: 1px;
  font-style: normal;
  font-weight: bold;
  overflow: hidden;
  padding: 3px;
  text-align: center;
  color: #333333;
  line-height: 21px;
}
table.table th a,
.table table th a {
  color: #006ca2;
}
table.table a,
.table table a {
  vertical-align: text-bottom;
}
table.table .tableRowOdd,
.table table .tableRowOdd {
  background-color: #ffffff;
}
table.table .tableRowEven,
.table table .tableRowEven {
  background-color: #f3f3f3;
}
table.table.fInherit,
.table.fInherit table {
  font-size: inherit;
}
table.table.border,
.table.border table {
  border: 1px solid #919191;
}
table.table.border table,
.table.border table table {
  margin: 0;
  border: none;
}
table.table.allBorder td,
.table.allBorder table td,
table.table.allBorder th,
.table.allBorder table th {
  border: 1px solid #919191;
  padding: 10px;
}
table.table.thN th,
.table.thN table th {
  font-weight: normal;
}
table.table.wAuto,
.table.wAuto table {
  width: auto;
}
table.displayPageTable {
  width: 100%;
}
table.displayPageTable.tdPad5 td,
table.displayPageTable.tdPad5 th {
  padding: 5px;
}
table.displayPageTable.tdBor td,
table.displayPageTable.tdBor th {
  border: 1px solid #ccc;
}
table.displayPageTable .tableRowOdd td,
table.displayPageTable .tableRowEven td {
  padding: 3px;
}
.tableWidthAuto .displayPageTable {
  width: auto;
}
.pagination td {
  vertical-align: top;
}
.pagination td.DMTableTD {
  padding-right: 10px;
}
.pagination a {
  color: #666;
}
