/* Quick fix - start */
.wpthemeInner,
.wpthemeSecondaryNav {
  width: auto;
}
table.layoutRow {
  width: 100%;
}
main .wpthemeOverflowAuto {
  overflow: inherit;
}
/* header.main a, */
nav.main a {
  text-decoration: none;
}
/* header.main a:hover, */
nav.main a:hover,
nav.main ul li.active a {
  text-decoration: none;
}
.oi-document:before {
  color: #e87722;
}
p:empty {
  display: none;
}
.ui-datepicker .ui-datepicker-title select {
  padding: 5px;
}

.mainWrap {
  &.whitelabel {
    .button.button-secondary,
    input[type="submit"].button-secondary,
    input[type="reset"].button-secondary,
    input[type="button"].button-secondary,
    .button.button-primary,
    input[type="submit"].button-primary,
    input[type="reset"].button-primary,
    input[type="button"].button-primary {
      background-color: #505152;
      border-color: #505152;
      color: #fff;
      //color: #000;

      &:hover,
      &:active {
        color: #505152;
        background-color: #ffffff;
      }
      &:active {
        box-shadow: 0 0 6px 1px #505152;
      }
    }

    header.main .infoBar aside > ul > li.userInfo {
      a {
        background-color: #505152;
      }
      ul {
        li {
          a {
            border-color: #505152;
            color: #505152;
            background-color: #ffffff;

            &:hover {
              color: #ffffff;
              background-color: #505152;
            }
          }
        }
      }
    }

    nav.main a:after {
      background-color: #505152;
    }

    .oi-document:before {
      color: #505152;
    }
  }
}
