* {
  box-sizing: border-box;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
ul li,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
article,
aside,
details,
figcaption,
figure,
footer,
main,
header,
menu,
nav,
section,
picture,
video {
  display: block;
}
p {
  margin-bottom: 15px;
  line-height: 1.33;
}
input,
textarea,
select {
  font-size: 15px;
  line-height: 1.33;
  font-family: "Open Sans", Arial, sans-serif;
  color: #000000;
}
code,
var {
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: .9rem;
}
.lt-ie9 code,
.lt-ie9 var {
  font-size: 90%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: 1;
  text-rendering: optimizelegibility;
  /* Fix the character spacing for headings */
}
b,
strong,
.strong {
  font-weight: bold;
}
em,
i,
.italic {
  font-weight: normal;
  font-style: italic;
}
cite {
  font-style: normal;
}
img,
fieldset {
  border: 0;
}
img,
video {
  max-width: 100%;
  height: auto;
  width: auto;
  /* ie8 */
}
video {
  width: 100%;
}
sub,
sup {
  font-size: .7em;
  /*line-height: 0;
    position: relative;
    vertical-align: baseline;
    letter-spacing: 0.13em;*/
}
sub {
  bottom: -0.3em;
}
ul {
  padding-left: 1em;
  -webkit-margin-start: 2px;
  list-style: disc;
}
