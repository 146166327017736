.CustomFooter {
  &.CustomFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "frutiger-light", Arial, sans-serif;
    font-size: 13px;
  }

  .pageSelect {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // .cusSelect select {
  //   height: auto;
  //   width: auto;
  // }
}
