[class^="oi-"],
[class*=" oi-"],
.io-,
button.ui-datepicker-trigger {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.oi-achievement:before {
  content: "\e900";
}
.oi-allergies:before {
  content: "\e901";
}
.oi-ambulance:before {
  content: "\e902";
}
.oi-asterisk:before {
  content: "\e903";
}
.oi-awards:before {
  content: "\e904";
}
.oi-blood-pressure:before {
  content: "\e905";
}
.oi-bookmark:before {
  content: "\e906";
}
.oi-calendar:before,
button.ui-datepicker-trigger:before {
  content: "\e907";
}
.oi-carb-counting:before {
  content: "\e908";
}
.oi-chat:before {
  content: "\e909";
}
.oi-clinical-overview:before {
  content: "\e90a";
}
.oi-clock:before {
  content: "\e90b";
}
.oi-cloud:before {
  content: "\e90c";
}
.oi-coaching:before {
  content: "\e90d";
}
.oi-communicate:before {
  content: "\e90e";
}
.oi-computer:before,
.oi-my-health-stats:before {
  content: "\e90f";
}
.oi-doc-consent-no:before {
  content: "\e910";
}
.oi-doc-consent:before {
  content: "\e911";
}
.oi-coverage:before {
  content: "\e912";
}
.oi-credit-card:before {
  content: "\e913";
}
.oi-customer-services:before {
  content: "\e914";
}
.oi-dental-exam:before {
  content: "\e915";
}
.oi-direct-mail:before {
  content: "\e916";
}
.oi-document:before,
.oi-document-cta:before {
  content: "\e917";
}
.oi-emotional-health:before {
  content: "\e919";
}
.oi-eye-exam:before {
  content: "\e91a";
}
.oi-family:before,
.oi-individuals-families:before {
  content: "\e91b";
}
.oi-finances:before {
  content: "\e91c";
}
.oi-folder-closed:before {
  content: "\e91d";
}
.oi-folder-open:before {
  content: "\e91e";
}
.oi-glucose:before {
  content: "\e91f";
}
.oi-go-green:before {
  content: "\e920";
}
.oi-government:before {
  content: "\e921";
}
.oi-guide:before {
  content: "\e922";
}
.oi-haz-mat:before {
  content: "\e923";
}
.oi-health-plan-benefits:before,
.oi-health-plans:before {
  content: "\e924";
}
.oi-health:before {
  content: "\e925";
}
.oi-home:before {
  content: "\e926";
}
.oi-hospitals-clinics:before {
  content: "\e927";
}
.oi-individual:before,
.oi-profile:before {
  content: "\e928";
}
.oi-lab-results:before {
  content: "\e929";
}
.oi-landline:before {
  content: "\e92a";
}
.oi-life-sciences:before {
  content: "\e92b";
}
.oi-make-claim:before {
  content: "\e92c";
}
.oi-mobile:before {
  content: "\e92d";
}
.oi-nurseline:before {
  content: "\e92f";
}
.oi-nutrition:before {
  content: "\e930";
}
.oi-overdue:before {
  content: "\e932";
}
.oi-partnership:before {
  content: "\e933";
}
.oi-people-employers:before,
.oi-employers:before {
  content: "\e934";
}
.oi-pharmacy:before {
  content: "\e935";
}
.oi-pin:before {
  content: "\e936";
}
.oi-population:before {
  content: "\e937";
}
.oi-providers:before,
.oi-provider:before {
  content: "\e938";
}
.oi-reimburse:before {
  content: "\e939";
}
.oi-results:before {
  content: "\e93a";
}
.oi-reviews:before,
.oi-blog:before {
  content: "\e93b";
}
.oi-secure:before {
  content: "\e93c";
}
.oi-sms-texting:before {
  content: "\e93d";
}
.oi-syringe:before {
  content: "\e93e";
}
.oi-thermometer:before {
  content: "\e93f";
}
.oi-tools:before {
  content: "\e940";
}
.oi-vitals:before {
  content: "\e941";
}
.oi-volunteering:before {
  content: "\e942";
}
.oi-walking:before {
  content: "\e943";
}
.oi-weight-loss:before {
  content: "\e944";
}
.oi-wellness:before {
  content: "\e945";
}
.oi-work:before {
  content: "\e946";
}
.oi-workers-comp:before {
  content: "\e947";
}
.oi-alert:before {
  content: "\e949";
}
.oi-arrow-down:before {
  content: "\e94c";
}
.oi-arrow-up:before {
  content: "\e94d";
}
.oi-attach:before {
  content: "\e94e";
}
.oi-back:before {
  content: "\e94f";
}
.oi-box-close:before {
  content: "\e951";
}
.oi-box-open:before {
  content: "\e952";
}
.oi-brightness:before {
  content: "\e953";
}
.oi-link-broken:before {
  content: "\e954";
}
.oi-caution:before {
  content: "\e956";
}
.oi-column-chart:before {
  content: "\e958";
}
.oi-consent-no:before {
  content: "\e959";
}
.oi-consent:before {
  content: "\e95a";
}
.oi-contacts:before {
  content: "\e95b";
}
.oi-doc-copy-no:before {
  content: "\e95c";
}
.oi-doc-copy:before {
  content: "\e95d";
}
.oi-download:before {
  content: "\e960";
}
.oi-edit-personalize:before {
  content: "\e961";
}
.oi-email-empty-trash:before,
.oi-trash:before {
  content: "\e962";
}
.oi-email-forward:before {
  content: "\e963";
}
.oi-email-full-trash:before,
.oi-trash-full:before {
  content: "\e964";
}
.oi-email-have-mail:before {
  content: "\e965";
}
.oi-email-reply-all:before {
  content: "\e966";
}
.oi-email-reply:before {
  content: "\e967";
}
.oi-email:before {
  content: "\e968";
}
.oi-error:before {
  content: "\e969";
}
.oi-export:before {
  content: "\e96a";
}
.oi-playback-fast-forward:before {
  content: "\e96b";
}
.oi-filter:before {
  content: "\e96c";
}
.oi-flagged-Item:before,
.oi-flagged-item:before,
.oi-flag:before {
  content: "\e96d";
}
.oi-forward-doc:before {
  content: "\e970";
}
.oi-forward:before {
  content: "\e971";
}
.oi-full-screen:before {
  content: "\e972";
}
.oi-help:before {
  content: "\e975";
}
.oi-history:before {
  content: "\e976";
}
.oi-import:before {
  content: "\e978";
}
.oi-info-tooltips:before {
  content: "\e979";
}
.oi-line-chart-isolated:before {
  content: "\e97a";
}
.oi-like:before {
  content: "\e97b";
}
.oi-line-chart:before {
  content: "\e97c";
}
.oi-link:before {
  content: "\e97d";
}
.oi-list:before {
  content: "\e97e";
}
.oi-location:before {
  content: "\e97f";
}
.oi-lock:before {
  content: "\e980";
}
.oi-menu:before {
  content: "\e981";
}
.oi-minimize-screen:before {
  content: "\e982";
}
.oi-new-window:before {
  content: "\e983";
}
.oi-new:before {
  content: "\e984";
}
.oi-no:before {
  content: "\e985";
}
.oi-oral-medication:before {
  content: "\e986";
}
.oi-pdf:before {
  content: "\e987";
}
.oi-pie-chart:before {
  content: "\e988";
}
.oi-playback-fast-reverse:before {
  content: "\e98a";
}
.oi-playback-forward:before {
  content: "\e98b";
}
.oi-playback-reverse:before {
  content: "\e98c";
}
.oi-plus:before {
  content: "\e98d";
}
.oi-power:before {
  content: "\e98e";
}
.oi-print:before {
  content: "\e98f";
}
.oi-redo:before {
  content: "\e991";
}
.oi-refresh-running:before {
  content: "\e992";
}
.oi-rows-chart:before {
  content: "\e993";
}
.oi-save:before {
  content: "\e994";
}
.oi-doc-search:before {
  content: "\e995";
}
.oi-search:before {
  content: "\e996";
}
.oi-settings:before {
  content: "\e997";
}
.oi-upload:before {
  content: "\e998";
}
.oi-share:before {
  content: "\e999";
}
.oi-sort-drag:before {
  content: "\e99a";
}
.oi-sort:before {
  content: "\e99b";
}
.oi-stacked-rows-vert-100:before {
  content: "\e99c";
}
.oi-stacked-rows-100:before {
  content: "\e99d";
}
.oi-stacked-rows-vert:before {
  content: "\e99e";
}
.oi-stacked-rows:before {
  content: "\e99f";
}
.oi-star-filled:before {
  content: "\e9a0";
}
.oi-star:before {
  content: "\e9a1";
}
.oi-success:before {
  content: "\e9a2";
}
.oi-undo:before {
  content: "\e9a5";
}
.oi-unlock:before {
  content: "\e9a6";
}
.oi-video:before {
  content: "\e9a7";
}
.oi-wifi:before {
  content: "\e9aa";
}
.oi-xray:before {
  content: "\e9ab";
}
.oi-yes:before {
  content: "\e9ac";
}
.oi-zoom-in:before {
  content: "\e9ad";
}
.oi-zoom-out:before {
  content: "\e9ae";
}
.oi-blog,
.oi-reviews {
  font-weight: bold;
}
