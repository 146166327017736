#mask {
  display: none;
  background: #000000;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  filter: alpha(opacity=80);
  z-index: 999;
}
.video-popup,
.global-popup {
  width: 804px;
  /*min-height:384px;*/
  min-height: 200px;
  display: none;
  background: #ffffff;
  font-size: 1.2em;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999;
  box-shadow: 0px 0px 20px #999;
  border-radius: 3px 3px 3px 3px;
  font: normal normal 10px Arial, Helvetica, sans-serif;
}
.video-popup {
  width: 800px;
  height: 450px;
  max-width: 100%;
  overflow: visible !important;
}
.video-popup iframe {
  max-width: 100%;
  max-height: 100%;
}
#video-box-inner {
  max-width: 100%;
  max-height: 100%;
}
img.btn_close {
  float: right;
  margin: -32px -32px 0 0;
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 767px) {
  .global-popup {
    width: 300px;
    height: 460px;
  }
  .video-popup {
    width: 450px !important;
    overflow: auto;
  }
  .video-popup img.btn_close {
    margin: -32px 0 0 0;
  }
}
@media only screen and (max-width: 480px) {
  .video-popup {
    width: 300px !important;
    max-height: 300px;
  }
}
