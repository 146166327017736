.outputDataCell {
  border-radius: 4px;
}

table.displayPageTable.tdBor table.gridTable td,
table.displayPageTable.tdBor table.gridTable th {
  border: none;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 13px;
  white-space: nowrap;
}

.gridTable .tableHeadRow {
  background-color: #63666a;
  color: #ffffff;
  font-family: "Frutiger-Bold", Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: .04rem;
  text-align: left;
}

.gridTable .tableRowEven {
  background-color: #efefee;
}

.gridTable .tableRowEven,
.gridTable .tableRowOdd {
  color: #000000;
  font-size: 13px;
  text-align: left;
}

table.displayPageTable table.gridTable th,
table.displayPageTable table.gridTable .tableRowOdd td,
table.displayPageTable table.gridTable .tableRowEven td {
  padding: 6px 16px;
}

.invoicemonthimagefolder_ColumnData .outputData {
  cursor: pointer;
}