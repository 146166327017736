.announcementCard{
    border:1px solid #ccc;
    padding:20px;
    border-radius: 5px;
    margin-bottom: 15px;

    .date{
        text-transform: uppercase;
        font-weight: 400;
        font-family: "Frutiger-Light", Arial, sans-serif;
    }

    .title{
        margin: 10px 0;
        font-weight: 400;
        font-size: 25px;
        font-family: "Frutiger-Light", Arial, sans-serif;
    }

    .brief{
        width: 100%;
       p{
        line-height: 22px;
       }
    }      
}