.pad0 {
  padding: 0px;
}
.pad5 {
  padding: 5px;
}
.pad10 {
  padding: 10px;
}
.pad15 {
  padding: 15px;
}
.pad20 {
  padding: 20px;
}
.pad25 {
  padding: 25px;
}
.pad30 {
  padding: 30px;
}
.pad35 {
  padding: 35px;
}
.pad40 {
  padding: 40px;
}
.pad45 {
  padding: 45px;
}
.pad50 {
  padding: 50px;
}
.padT0 {
  padding-top: 0px;
}
.padT5 {
  padding-top: 5px;
}
.padT10 {
  padding-top: 10px;
}
.padT15 {
  padding-top: 15px;
}
.padT20 {
  padding-top: 20px;
}
.padT25 {
  padding-top: 25px;
}
.padT30 {
  padding-top: 30px;
}
.padT35 {
  padding-top: 35px;
}
.padT40 {
  padding-top: 40px;
}
.padT45 {
  padding-top: 45px;
}
.padT50 {
  padding-top: 50px;
}
.padT55 {
  padding-top: 55px;
}
.padT60 {
  padding-top: 60px;
}
.padT65 {
  padding-top: 65px;
}
.padT70 {
  padding-top: 70px;
}
.padT75 {
  padding-top: 75px;
}
.padT80 {
  padding-top: 80px;
}
.padT85 {
  padding-top: 85px;
}
.padT90 {
  padding-top: 90px;
}
.padT95 {
  padding-top: 95px;
}
.padT100 {
  padding-top: 100px;
}
.padT105 {
  padding-top: 105px;
}
.padT110 {
  padding-top: 110px;
}
.padT115 {
  padding-top: 115px;
}
.padT120 {
  padding-top: 120px;
}
.padB0 {
  padding-bottom: 0px;
}
.padB5 {
  padding-bottom: 5px;
}
.padB10 {
  padding-bottom: 10px;
}
.padB15 {
  padding-bottom: 15px;
}
.padB20 {
  padding-bottom: 20px;
}
.padB25 {
  padding-bottom: 25px;
}
.padB30 {
  padding-bottom: 30px;
}
.padB35 {
  padding-bottom: 35px;
}
.padB40 {
  padding-bottom: 40px;
}
.padB45 {
  padding-bottom: 45px;
}
.padB50 {
  padding-bottom: 50px;
}
.padB55 {
  padding-bottom: 55px;
}
.padB60 {
  padding-bottom: 60px;
}
.padB65 {
  padding-bottom: 65px;
}
.padB70 {
  padding-bottom: 70px;
}
.padB75 {
  padding-bottom: 75px;
}
.padB80 {
  padding-bottom: 80px;
}
.padB85 {
  padding-bottom: 85px;
}
.padB90 {
  padding-bottom: 90px;
}
.padB95 {
  padding-bottom: 95px;
}
.padB100 {
  padding-bottom: 100px;
}
.padB105 {
  padding-bottom: 105px;
}
.padB110 {
  padding-bottom: 110px;
}
.padB115 {
  padding-bottom: 115px;
}
.padB120 {
  padding-bottom: 120px;
}
.padL0 {
  padding-left: 0px;
}
.padL5 {
  padding-left: 5px;
}
.padL10 {
  padding-left: 10px;
}
.padL15 {
  padding-left: 15px;
}
.padL20 {
  padding-left: 20px;
}
.padL25 {
  padding-left: 25px;
}
.padL30 {
  padding-left: 30px;
}
.padL35 {
  padding-left: 35px;
}
.padL40 {
  padding-left: 40px;
}
.padL45 {
  padding-left: 45px;
}
.padL50 {
  padding-left: 50px;
}
.padR0 {
  padding-right: 0px;
}
.padR5 {
  padding-right: 5px;
}
.padR10 {
  padding-right: 10px;
}
.padR15 {
  padding-right: 15px;
}
.padR20 {
  padding-right: 20px;
}
.padR25 {
  padding-right: 25px;
}
.padR30 {
  padding-right: 30px;
}
.padR35 {
  padding-right: 35px;
}
.padR40 {
  padding-right: 40px;
}
.padR45 {
  padding-right: 45px;
}
.padR50 {
  padding-right: 50px;
}
.mar0 {
  margin: 0px;
}
.mar5 {
  margin: 5px;
}
.mar10 {
  margin: 10px;
}
.mar15 {
  margin: 15px;
}
.mar20 {
  margin: 20px;
}
.mar25 {
  margin: 25px;
}
.mar30 {
  margin: 30px;
}
.mar35 {
  margin: 35px;
}
.mar40 {
  margin: 40px;
}
.mar45 {
  margin: 45px;
}
.mar50 {
  margin: 50px;
}
.marT5 {
  margin-top: 5px;
}
.marT10 {
  margin-top: 10px;
}
.marT15 {
  margin-top: 15px;
}
.marT20 {
  margin-top: 20px;
}
.marT25 {
  margin-top: 25px;
}
.marT30 {
  margin-top: 30px;
}
.marT35 {
  margin-top: 35px;
}
.marT40 {
  margin-top: 40px;
}
.marT45 {
  margin-top: 45px;
}
.marT50 {
  margin-top: 50px;
}
.marB0 {
  margin-bottom: 0px;
}
.marB5 {
  margin-bottom: 5px;
}
.marB10 {
  margin-bottom: 10px;
}
.marB15 {
  margin-bottom: 15px;
}
.marB20 {
  margin-bottom: 20px;
}
.marB25 {
  margin-bottom: 25px;
}
.marB30 {
  margin-bottom: 30px;
}
.marB35 {
  margin-bottom: 35px;
}
.marB40 {
  margin-bottom: 40px;
}
.marB45 {
  margin-bottom: 45px;
}
.marB50 {
  margin-bottom: 50px;
}
.marL5 {
  margin-left: 5px;
}
.marL10 {
  margin-left: 10px;
}
.marL15 {
  margin-left: 15px;
}
.marL20 {
  margin-left: 20px;
}
.marL30{
  margin-left: 30px;
}
.marL60{
  margin-left: 60px;
}
.marR5 {
  margin-right: 5px;
}
.marR10 {
  margin-right: 10px;
}
.marR15 {
  margin-right: 15px;
}
.marR20 {
  margin-right: 20px;
}
.marR25 {
  margin-right: 25px;
}
.marR30 {
  margin-right: 30px;
}
.marR35 {
  margin-right: 35px;
}
.marR40 {
  margin-right: 40px;
}
.padT1em {
  padding-top: 1em;
}
.padT2em {
  padding-top: 2em;
}
.padT3em {
  padding-top: 3em;
}
.padT4em {
  padding-top: 4em;
}
.padB0em {
  padding-bottom: 0em;
}
.padB1em {
  padding-bottom: 1em;
}
.padB2em {
  padding-bottom: 2em;
}
.padB3em {
  padding-bottom: 3em;
}
.padB4em {
  padding-bottom: 4em;
}
.marAuto {
  margin-left: auto;
  margin-right: auto;
}
.padT4rem {
  padding-top: 4rem;
}
/* Float */
.floatL {
  float: left;
}
.floatR {
  float: right;
}
.noFloat {
  float: none;
}
/* Alignment */
.c {
  text-align: center;
}
.r {
  text-align: right;
}
.l {
  text-align: left;
}
.j {
  text-align: justify;
}
.vTop {
  vertical-align: top;
}
.vMiddle {
  vertical-align: middle;
}
.vBottom {
  vertical-align: bottom;
}
.vertTextBot {
  vertical-align: text-bottom;
}
/* Text related */
.b {
  font-weight: bold;
}
.n {
  font-weight: normal;
}
.u {
  text-decoration: underline;
}
.i {
  font-style: italic;
}
.noI {
  font-style: normal;
}
.noU {
  text-decoration: none;
}
.low {
  text-transform: lowercase;
}
.cap {
  text-transform: uppercase;
}
.capF {
  text-transform: capitalize;
}
.noCap {
  text-transform: none;
}
/* Line-Height */
.lH14 {
  line-height: 14px;
}
.lH16 {
  line-height: 16px;
}
.lH18 {
  line-height: 18px;
}
.lH20 {
  line-height: 20px;
}
.lH22 {
  line-height: 22px;
}
.lH24 {
  line-height: 24px;
}
.lH26 {
  line-height: 26px;
}
.lH28 {
  line-height: 28px;
}
.lH30 {
  line-height: 30px;
}
.lH1em {
  line-height: 1em;
}
.lH1Hem {
  line-height: 1.5em;
}
/* Default Height */
.hFull {
  height: 100%;
}
.hInherit {
  height: inherit;
}
/* cursor */
.curPointer,
.popup {
  cursor: pointer;
}
/* Font-color and Sizes */
.c_black {
  color: #000000;
}
.c_white {
  color: #ffffff;
}
.c_primary {
  color: #e87722;
}
/* Display */
.block,
.show {
  display: block;
}
.inline {
  display: inline;
}
.inlineBlock {
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
}
.hide,
.cusSelect .s-hidden {
  display: none;
}
.hidden {
  visibility: hidden;
}
.brHide br {
  display: none;
}
/* Overflows */
.overHidden {
  overflow: hidden;
}
.overAuto {
  overflow: auto;
}
/* Positions */
.posAbs {
  position: absolute;
}
.posRel {
  position: relative;
}
.posStatic {
  position: static;
}
.posAllSides {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.posT0 {
  top: 0;
}
.posR0 {
  right: 0;
}
/* Common Background */
.bg_white {
  background: #ffffff;
}
.bg_black {
  background: #000000;
}
.bg_green {
  background: #468847;
}
.navHoverBg,
nav.main a:hover,
nav.main ul li a.active {
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    #ededec 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    #ededec 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #ededec 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ededec', GradientType=0);
}
/* Borders */
/*.botBor {
  background: url("/assets/images/icon/botBorder.png") left bottom repeat-x;
}*/
/* Width */
.maxWidthAuto,
.maxWidthAuto img {
  max-width: none;
}
.wFull,
.wFull img,
.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.defBoxShadow {
  box-shadow: 0 0 5px #ccc;
}
/* Wrap */
.noWrap,
.cusSelect .select .styledSelect {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pre {
  white-space: nowrap;
}
/* Images */
.imgReset,
.imgReset img {
  display: inline-block;
}
/* Opacity */
.op50 {
  opacity: 0.5;
}
.op100 {
  opacity: 1;
}
/* font sizes */
.f12 {
  font-size: 12px;
}
.f13 {
  font-size: 13px;
}
.f14 {
  font-size: 14px;
}
.f15 {
  font-size: 15px;
}
.f16 {
  font-size: 16px;
}
.f18 {
  font-size: 18px;
}
.f20 {
  font-size: 20px;
}
.f22 {
  font-size: 22px;
}
.f25 {
  font-size: 25px;
}
.f30 {
  font-size: 30px;
}
.f36 {
  font-size: 36px;
}
.f42 {
  font-size: 42px;
}
/* images's */
.imgFull img {
  width: 100%;
}
.img-responsive {
  display: block;
  max-width: 100%;
}
.mW100 img {
  max-width: 100%;
}
/* border radius */
.borR5px {
  border-radius: 5px !important;
}
/* Content Box */
.contentBox {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.txtSub {
  font-family: "Open Sans", Arial, sans-serif !important;
  padding-left: 20px;
  position: relative;
  font-size: 15px;
}
.txtSub:before {
  position: absolute;
  top: 5px;
  left: 0;
  border: 0;
  font-family: "Optum-Micro-interaction-Icons" !important;
}
// .cux-icon-warning_filled:before {
//   font-family: 'Optum-Micro-interaction-Icons' !important;
// }
.cux-icon-warning_filled:before {
  font-family: "Optum-Icon-Font" !important;
}
// .font-fl {
//   font-family: "Frutiger-Light", Arial, sans-serif;
//   color: #505152;
// }
