.rc-tree li span.rc-tree-switcher.rc-tree-icon__customize,
.rc-tree li span.rc-tree-checkbox.rc-tree-icon__customize,
.rc-tree li span.rc-tree-iconEle.rc-tree-icon__customize .rc-tree-iconElem {
  background-image: url("/assets/images/icon/jstreeIcons.png");
}

.rc-tree li span.rc-tree-iconEle {
  vertical-align: top;
}

.rc-tree li span.rc-tree-switcher.rc-tree-icon__customize,
.rc-tree li span.rc-tree-checkbox.rc-tree-icon__customize,
.rc-tree li span.rc-tree-iconEle.rc-tree-icon__customize {
  line-height: 24px !important;
  width: 24px;
  height: 24px;
  text-align: center;
}

.rc-customIcon {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.rc-fileIcon {
  background-position: -260px -4px;
}

.rc-tree li .rc-tree-node-content-wrapper {
  height: unset;
}

.rc-tree li {
  position: relative;
}

.rc-tree-line-item {
  position: absolute;
  right: 44px;
}

.rc-tree-line-item1 {
  position: absolute;
  right: 230px;
}

.rc-tree li .rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-normal {
  height: unset;
  width: 60%;
  margin-left: -20px;
  .rc-tree-title {
    span:first-child {
      display: inline-block;
      white-space: normal;
      word-break: break-word;
    }
  }
}

.inputWithButton {
  position: relative;
  height: 60px;
  width : 285px;
}

.inputWithButton input{
    width: 70%;
    height: 25px;
    // padding-right: 60px;
    padding: 6px 25px !important;

}

.inputWithButton i {
  position: absolute;
  top : 6px;
  font-size: larger;
  // right: -80px !important;
}

.searchIcon{
  margin-left: 5px;
  color: grey;
}

.clearIcon{
  margin-left: -22px;
}

.centerAlign{
  margin-top: 15px;
  margin-left: 230px;
}

.searchBarHeight{
  min-height: 60px;
}

.marL4m {
  margin-left: 18px !important;
  height: 20px !important;
}

.imgIcon{
  height: 13px !important;
  width: 21px !important;
  margin-top: 2px;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.4;
}

.errDialogModal {
  .MuiDialog-paper {
    border-radius: 8px;
  }

  .rxCometForm
    .form-group.field
    .body-text-small:not(.productsCardText).header-notes {
    margin-bottom: 30px !important;
  }

  .MuiDialogTitle-root {
    padding: 36px 60px 12px 40px;
  }

  .MuiDialogContent-root {
    padding: 0px 60px 15px 40px;
    overflow-y: auto !important;

    .button {
      text-transform: inherit;

      & + .button {
        margin-left: 20px;
      }
    }
  }
  .MuiDialog-paperWidthSm {
    max-width: 700px;
  }
  .MuiDialogContent-dividers {
    border: 0;
    padding: 0 0 0 40px;
    margin: 0px 60px 36px 0;
    min-height: 200px;
  }

  .MuiTypography-h2 {
    font-family: "Frutiger-Light", Arial, sans-serif;
    font-size: 36px;
    line-height: normal;
    letter-spacing: normal;
  }

  &.sm600 {
    // .MuiDialogContent-root {
    //   width: 600px;
    // }
    .MuiDialogContent-root .form-group .control-label {
      margin: 0 0 5px 0 !important;
      color: #505152;
      display: block;
    }
  }

  &.dialog-body {
    .MuiDialog-paperWidthSm {
      max-width: 700px;
    }
  }
}
