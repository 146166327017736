.ClientDropdown[class*="MuiInput-underline"]:before,
.ClientDropdown[class*="MuiInput-underline"]:after {
  content: none;
}

#menu-.ClientDropdownMenu [class*="MuiList-padding"] {
  padding: 0;
}

#menu-.ClientDropdownMenu [class*="MuiMenu-paper"] {
  border: 1px solid #cccccc;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-top: -1px;
  max-height: 300px;
  width: 185px;
}

.clientForm .Selecterror .ClientDropdown [class*="MuiSelect-root"] {
  border: none;
}