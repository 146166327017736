ul.default.subNav {
  border-top: none;
  color: #006ca2;
  padding-left: 2px;
}
ul.default.subNav li {
  margin-bottom: 5px;
}
.subNav .social {
  border-top: none;
}
.subNav {
  border-top: solid 1px #aebdc4;
}
.subNav ul {
  list-style: none;
  -webkit-margin-start: 0;
}
.subNav ul a {
  color: #333741;
}
.subNav ul a:hover {
  text-decoration: underline;
}
.subNav ul li {
  margin-bottom: 7px;
}
.imageLoader {
  margin-left: 15px; 
}
